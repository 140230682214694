import { useState, useEffect, useRef, Fragment, memo, useMemo } from "react";
import styles from "./DataGridNews.module.scss";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroller";
import { useRecoilValue } from "recoil";
import { Box, Card, CardContent, CircularProgress, Chip, Rating, Tooltip, Typography, useMediaQuery } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import DialogBasic from "@/components/dialog/Dialog";
import Skeleton from "@/components/skeleton/Skeleton";
import { getDataGridNewDetail, getDataGridNews } from "@/controllers/news";
import { Stock } from "@/models/stock";
import { textColorState, greyLighterState, greyDarkerState, themeState, ortexColorState } from "@/lib/store";
import { classNames, dateParser } from "@/lib/utils";

type Props = {
    amount_news?: { desktop: number; mobile: number; "4k": number };
    custom_height?: string;
    header_layout?: string;
    id: number;
    mobile_design?: boolean;
    options: Record<string, any>;
    regionId?: number;
    showStock?: boolean;
    sortBy?: string;
    stock?: Stock;
};

export const DataGridNews = ({ amount_news = { desktop: 10, mobile: 5, "4k": 15 }, custom_height, header_layout = "30% 20% 50%", id, mobile_design, options, regionId, showStock, sortBy, stock }: Props) => {
    const greyLighter = useRecoilValue(greyLighterState);
    const greyDarker = useRecoilValue(greyDarkerState);
    const textColor = useRecoilValue(textColorState);
    const ortexColor = useRecoilValue(ortexColorState);
    const theme = useRecoilValue(themeState);

    const [newsList, setNewsList] = useState([]);
    const [newsDetail, setNewsDetail] = useState<Record<string, any>>([]);
    const [modal, setModal] = useState(false);
    const [obfuscated, setObfuscated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const isMobile = useMediaQuery("(max-width: 768px)");
    const is4k = useMediaQuery("(min-width: 3840px)");

    const newsAmount = isMobile ? 8 : is4k ? 25 : 20;
    const page = useRef(1);
    const totalPages = useRef(1);
    const finalId = stock?.company_id || regionId || id;

    const apiParams = sortBy === "importance" ? { sortModel: { priority: "desc", published_at: "desc" } } : {};

    const getNewsDetail = (id: string) => {
        if (obfuscated) return;

        getDataGridNewDetail(id)
            .then((res) => {
                setNewsDetail(res);
                setModal(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const fetchNews = (loadMore: boolean) => {
        if (finalId === undefined) return;
        !loadMore && setIsLoading(true);
        getDataGridNews({ page_size: newsAmount, page: page.current, filter_options: apiParams, id: finalId, data: options })
            .then((res) => {
                if (res.obfuscated) {
                    setObfuscated(true);
                    delete res.obfuscated;
                }

                if (loadMore) {
                    setNewsList((prevState) => [...prevState, ...res.rows]);
                } else {
                    totalPages.current = Math.ceil(res.length / 30);
                    setNewsList(res.rows);
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        fetchNews(false);
    }, [stock, regionId, id]);

    const loadMoreNews = () => {
        if (!isLoading) {
            page.current++;
            fetchNews(true);
        }
    };

    if (isLoading || id === undefined) {
        return <Skeleton variant="rounded" width="100%" height="100%" loading={isLoading || id === undefined} />;
    }

    return (
        <Box className={styles.NewNewsList}>
            <Box className={styles.container} style={{ height: custom_height || "auto" }}>
                <InfiniteScroll
                    id="infinite-scroll"
                    style={{ height: "100%" }}
                    pageStart={0}
                    loadMore={loadMoreNews}
                    loader={
                        <Box key={"loader"} sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingY: 2 }}>
                            <CircularProgress />
                        </Box>
                    }
                    useWindow={false}
                    initialLoad={false}
                    hasMore={page.current < totalPages.current}
                >
                    {newsList.length > 0 ? (
                        newsList.map((news) => {
                            const { newsId, publishedAt, source, newsImportance, stocks, title } = news;
                            const formattedDate = dateParser(dayjs(publishedAt), "D MMM YYYY [at] HH:mm");
                            const color = newsImportance / 2 === 5 ? "#faaf00" : theme === "dark" ? greyLighter : greyDarker;
                            const sourceLabel = <Chip size="small" label={source[0]} variant="outlined" sx={{ borderColor: color, color: color }} />;
                            const stocksContent = showStock && stocks && stocks.length > 0 && (
                                <Box className={styles.stocks}>
                                    {stocks.slice(0, 2).map((stock, index) => (
                                        <Tooltip key={index} title={obfuscated ? "" : stock.name} arrow placement="bottom-start">
                                            <Chip size="small" label={stock.ticker} variant="filled" sx={{ borderColor: ortexColor }} />
                                        </Tooltip>
                                    ))}
                                </Box>
                            );

                            return (
                                <Card
                                    key={newsId}
                                    sx={{
                                        borderBottom: theme === "dark" ? "1px solid rgb(62, 62, 62)" : "none",
                                        backgroundColor: "transparent",
                                        margin: 0,
                                        marginY: 1,
                                        width: "99%",
                                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
                                        marginX: "auto",
                                        "&:hover": {
                                            cursor: obfuscated ? "default" : "pointer",
                                        },
                                    }}
                                    elevation={2}
                                    onClick={() => getNewsDetail(newsId)}
                                >
                                    <CardContent className={classNames(styles["card-content"], obfuscated && "obfuscated")}>
                                        <Box className={styles["card-header"]} sx={{ gridTemplateColumns: mobile_design ? "50% 50%" : header_layout }}>
                                            {isMobile || mobile_design ? (
                                                <>
                                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginBottom: 0.5 }}>
                                                        <Typography className={styles["header-content"]} style={{ color: theme === "dark" ? greyLighter : greyDarker }}>
                                                            {formattedDate}
                                                        </Typography>
                                                        <Tooltip arrow title={obfuscated ? "" : source[1]}>
                                                            {sourceLabel}
                                                        </Tooltip>
                                                    </Box>
                                                    <Box className={styles["source-stock"]} sx={{ marginBottom: 0.5 }}>
                                                        <Tooltip title={obfuscated ? "" : newsImportance / 2} arrow>
                                                            <Box>
                                                                <Rating value={newsImportance / 2} readOnly size="small" className={styles.rating} precision={0.1} emptyIcon={<StarIcon style={{ color: greyLighter }} fontSize="inherit" />} />
                                                            </Box>
                                                        </Tooltip>
                                                        {stocksContent}
                                                    </Box>
                                                </>
                                            ) : (
                                                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                    <Typography variant="newsCardDate" sx={{ color: theme === "dark" ? greyLighter : greyDarker }}>
                                                        {formattedDate}
                                                    </Typography>
                                                    <Box sx={{ display: "flex", justifyContent: "flex-start", width: "fit-content" }}>
                                                        <Box className={styles["source-stock"]} sx={{ width: "fit-content" }}>
                                                            {stocksContent}
                                                            <Tooltip title={obfuscated ? "" : source[1]} arrow placement="bottom-start">
                                                                {sourceLabel}
                                                            </Tooltip>
                                                        </Box>
                                                        <Tooltip title={obfuscated ? "" : newsImportance / 2} arrow>
                                                            <Box>
                                                                <Rating value={newsImportance / 2} readOnly size="small" className={styles.rating} precision={0.1} emptyIcon={<StarIcon style={{ color: greyLighter }} fontSize="inherit" />} />
                                                            </Box>
                                                        </Tooltip>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box sx={{ color: textColor }}>
                                            <Typography variant="body2">{title}</Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            );
                        })
                    ) : (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                            <Typography variant="overline" component="p" sx={{ color: textColor }} className={styles.noNewsText}>
                                No News Available
                            </Typography>
                        </Box>
                    )}
                </InfiniteScroll>
                <DialogBasic
                    isOpen={modal}
                    onClose={setModal}
                    title={newsDetail?.title}
                    source_fullname={newsDetail?.sourceFullname}
                    published_at={newsDetail?.publishedAt}
                    description={newsDetail?.description}
                    content={newsDetail?.articleHtml}
                    draggable
                />
            </Box>
        </Box>
    );
};

export const MemoDataGridNews = memo(DataGridNews);
